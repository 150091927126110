<template>
  <div
    v-loading="loading"
    class="text-gray-700 text-center flex justify-center"
  >
    <div class="w-1/2">
      <div class="text-white text-center">
        <p class="text-md">
          Selamat datang di
        </p>
        <p class="text-xl">
          Sistem Monitoring CCTV Kota Malang
        </p>
      </div>
      <el-form
        ref="form"
        class="self-auto my-8"
        label-width="120px"
      >
        <el-form-wrapper :validator="$v.form">
          <el-form-group
            label-position="top"
            label-width="0px"
            name="Username"
            :validator="$v.form.username"
          >
            <el-input
              v-model="form.username"
              placeholder="Username"
              @input="$v.form.username.$touch()"
              @keyup.enter.native="doLogin"
            />
          </el-form-group>
          <el-form-group
            label-position="top"
            label-width="0px"
            name="Password"
            :validator="$v.form.password"
          >
            <el-input
              v-model="form.password"
              placeholder="Password"
              show-password
              @input="$v.form.password.$touch()"
              @keyup.enter.native="doLogin"
            />
          </el-form-group>
          <el-button
            :disabled="$v.$invalid"
            type="primary"
            class="w-full"
            @click="doLogin"
          >
            Login
          </el-button>
        </el-form-wrapper>
      </el-form>
    </div> 
  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      loading: false,
      form: {
        username: null,
        password: null
      }
    }
  },
  mounted() {
    
  },
  validations: {
    form: {
      username: { required },
      password: { required },
    }
  },
  methods: {
    doLogin() {
      this.loading = true;
      this.$store.dispatch('DO_LOGIN', this.form).then(() => {
        this.loading = false;
        this.$message({
          type: 'success',
          message: 'Login Success!'
        });
        this.$router.push('/dashboard/data')
      }).catch(err => {
        this.loading = false;
        this.$message({
          type: 'error',
          message: err.response.data.message
        });
      })
    }
  }
}
</script>

<style>

</style>
